<template>
    <div v-if="loading == false">
        <ChirpList
            class="table-scroll width-150vw"
            :data_source="rows"
            @rowSelected="getFile"
            :loadingColumns="loadingTable"
            :column_meta="columnMeta"
            :totalCount="totalCount"
            :storeKey="storeKey"
            :useOverride="useOverride"
            :preventNavOnColLabel="'shared to portal'"
            @specialCellClicked="handleShareToPortal"
            @cancelOverride="handleCancelOverride"
        />
    </div>
    <div v-else>Loading...</div>
</template>

<script>
    import { openPdf } from '@/util/pdf';

    const api_root = 'documents';

    import { file } from '@/util/apiRequests';
    import ChirpList from '@/components/general/list/ChirpList';
    // import { openPdf } from '@/util/pdf'
    import { openImage } from '@/util/image';
    import { saveFile } from '@/util/genericUtilityFunctions';
    import { tryGetFilter } from '@/util/tryGetFilter';
    import qs from 'qs';

    export default {
        name: 'Documents',
        components: { ChirpList },
        props: {
            overrideFilter: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                rows: [],
                totalCount: 0,
                loading: true,
                loadingTable: false,
                useOverride: false,
                columnMeta: [
                    { field: 'date', headerText: 'Date' },
                    { field: 'file_name', headerText: 'File Name' },
                    { field: 'first_name', headerText: 'First Name' },
                    { field: 'last_name', headerText: 'Last Name' },
                    { field: 'user', hide: 1 },
                    { field: 'user_id', hide: 1 },
                    { field: 'client_id', hide: 1 },
                    { field: 'tags', headerText: 'Tags' },
                    { field: 'file_type', headerText: 'File Type' },
                    { field: 'file_kind', headerText: 'File Kind' },
                    { field: 'downloads', headerText: 'Downloads' },
                    // { field: 'shared_to_portal', headerText: 'Shared to Portal' },
                    {
                        field: 'shared_to_portal',
                        headerText: 'Shared to Portal',
                        editType: 'booleanedit',
                        type: 'boolean',
                        displayAsCheckBox: 'true',
                        allowEditing: true
                    },
                    { field: 'filled_by_client', headerText: 'Filled by Client' },
                    { field: 'signed_by_client', headerText: 'Signed by Client' }
                ],
                criteriaProps: {}
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {

                if(this.$route.query.storeKey){
                    return this.$route.query.storeKey;
                }
                else if(this.pageMeta?.page){
                    return this.pageMeta.page;
                }
                else{
                    return 'documents';
                }
            }
        },
        methods: {
            async getFile(f) {
                try {
                    if (f.id) {
                        let result = await this.$api.get(file.getFile(f.id));
                        if (result.data.file.file_type == 'pdf' || result.data.file.file_type == '.pdf') {
                            openPdf(result.data?.file?.Body, result.data?.file?.file_name);
                        } else if (result.data.file.originalContentType.includes('image')) {
                            openImage(result.data?.file?.Body);
                        } else {
                            saveFile(
                                result.data?.file?.Body,
                                result.data?.file?.originalContentType,
                                result.data?.file?.file_name
                            );
                        }
                    } else {
                        this.$toasted.error('No file found');
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            async load_data() {
                const body = {
                    criteria: this.criteria
                };
                const res = await this.$api.post(`/${api_root}`, body);

                this.rows = res.data.rows.map((element) => {
                    if (element?.signed_by_client !== undefined) {
                        element.signed_by_client = String(element.signed_by_client) === '1' ? 'Yes' : 'No';
                    }
                    if (element?.filled_by_client !== undefined) {
                        element.filled_by_client = String(element.filled_by_client) === '1' ? 'Yes' : 'No';
                    }

                    return element;
                });

                this.totalCount = res.data.total_count;

                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                let { override, overrideFilter, storeKey } = this.$route.query ?? {};
                if(override && overrideFilter && storeKey){
                    this.useOverride = true;
                    overrideFilter = qs.parse(overrideFilter);
                    this.overrideFilter = overrideFilter;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: storeKey,
                        criteria: this.overrideFilter
                    })
                }
                if (this.overrideFilter && !override) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter
                    });
                }

                await this.load_data();
            },
            async modifyCriteria(criteria) {
                await this.load_data();
            },
            async handleShareToPortal(args) {
                const res = await this.$api.put(file.updateFile(args.data.id), {
                    shared_to_portal: args.data.shared_to_portal === 0 ? 1 : 0
                });

                if (res.status >= 300) {
                    //this.$toasted.error('Failed to update shared to portal status');
                    return;
                }

                this.$toasted.success('Successfully updated shared to portal status');

                await this.load_data();
            },
            handleCancelOverride() {
                this.useOverride = false;
            }
        },
        watch: {
            criteria: {
                deep: true,
                async handler() {
                    await this.load_data();
                }
            }
        },
        async created() {
            await this.init();
        }
    };
</script>
